@import "./variables.less";

#root {
  height: 100%;
}

.ant-layout.main-layout {
  min-height: 100%;
  overflow: hidden;
  height: 100%;
}

.header-title {
    color: white;
    float: left;
    font-size: 16px;
    font-weight: bold;
    margin-right: 32px;
}

.avatar-icon {
    float: right;
    cursor: pointer;
}

.header-content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    .tab-divider {
      height: 28px;
      background-color: #FFFFFF;
      opacity: 0.2;
      margin: 0;
    }
    .logo-divider {
      height: 28px;
      background-color: #FFFFFF;
      opacity: 0.2;
    }
    .initial-tabs {
      margin-left: 30px;
      display: inline-flex
    }
    .main-menu-container {
  
      .ant-menu {
        .ant-menu-item {
          i {
            height: 18px;
            margin-right: 15px;
            &.archive {
              filter: brightness(0) invert(1);
            }
          }
          width: 116px;
        }
  
        .ant-menu-item:after {
          display: none;
        }
      }
    }
}

.ant-layout-content {
    background-color: white;
}

.ant-layout-content.main-layout-content {
  padding: 20px 40px 0px;
  margin-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;
}

body.no-header {
  .ant-layout-header.app-page-header {
    display: none;
  }
}

.ant-layout-header.app-page-header {
    background: @primary-color;
    font-size: 15px;
    height: 50px;
    position: fixed;
    width: 100%;
    z-index: 10000;
  
    .ant-menu-submenu {
      border: none;
      height: 50px;
  
      .ant-menu-submenu-title {
        color: white;
      }
    }
  
    .ant-menu-submenu:hover, .ant-menu-submenu.ant-menu-submenu-active, .ant-menu-submenu.ant-menu-submenu-open {
      border: none;
      color: white;
    }
  
    .ant-menu-horizontal {
      border: none;

      .ant-menu-item::after {
          display: none;
      }
    }
  
    .ant-menu {
      background: @primary-color;
      color: #fff;
      flex-grow: 1;
      flex-shrink: 1;
  
      .ant-menu-item {
        border: none;
        font-size: 14px;
        height: 50px;
        padding: 0 15px;
        top: 0;
  
        .alpha-icon.xs {
          font-size: 16px;
          height: 16px;
          margin-right: 12px;
          vertical-align: middle;
          width: 16px;
        }
      }
  
      .ant-menu-item.ant-menu-item-active {
        color: #fff;
      }
    
      .ant-menu-item.ant-menu-item-selected {
        color: #fff;
        background: @primary-color-hover;
      }
  
      .ant-menu-item:hover {
        color: #fff;
        background: @tab-background-color-hover;
  
        .tab-title, .btn-container {
          border-color: @tab-background-color-hover;
        }
      }
    }
  
    .controls-container {
      align-items: center;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-end;
  
      .user-control {
        cursor: pointer;
        padding: 0 12px;
  
        i.alpha-icon {
          margin: 0;
        }
      }

      i.bell {
        height: 17px;
        margin-right: 20px;
      }
  
      .user-control.has-notifications {
        padding-right: 20px;
      }
  
      .user-control:hover {
        background-color: @primary-color-hover;
      }
  
      .profile-icon-container {
        cursor: pointer;
        padding-left: 12px;
  
        .ant-avatar {
          height: 36px;
          line-height: 36px;
          width: 36px;
        }
  
        .avatar-icon {
          display: flex;
          flex-flow: row;
          height: 50px;
          align-items: center;
        }
      }
      // .profile-icon-container:hover {
      //   background-color: @primary-color-hover;
      // }
    }
}

.table-container {
  height: calc(100vh - 122px);
  .table-container-header {
    display: flex;
    align-items: center;
  }
  .table-container-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 200px);

    .ant-table {
      .table-actions {
        display: none;
      }
      .ant-table-row:hover {
        .table-actions {
          display: flex;

          i:not(:first-child),
          .anticon {
            margin-left: 10px;
          }

          .anticon {
            color: @alpha-background-grey-color;
          }
        }
      }
    }
    
  }
}

.ant-modal.roche-client-dialog {
  .ant-form-item-label > label {
    color: @alpha-font-color-gray;
    font-size: 11px;
  }
  .ant-input-lg {
    padding: 8px 11px;
    font-size: 14px;
  }

  .ant-form-item-explain {
    font-size: 12px;
    margin-top: 6px;
  }

  .ant-input-affix-wrapper {
    padding: 8px 11px;
    font-size: 14px;

    & > input.ant-input {
      padding: 0;
    }
  }

  .ant-select-lg {
    font-size: 14px;
  }

  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(100vh - 260px);
      overflow: auto;
    }
    .dialog-administation-role-form-item {
      .ant-checkbox {
        height: 16px;
      }

      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0px;
      }

      .ant-form-item-label {
        padding: 0 0 20px;
      }
    }

    .ant-modal-footer {
      padding: 15px 20px;
    }
  }
  .alpha-portal-upload-dragger {
    .ant-upload.ant-upload-btn {
      height: 152px;
    }
  }
}

.ant-modal.roche-client-dialog.scrollable {
  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(100vh - 250px);
      overflow: auto;
    }
  }
}

.ant-modal.roche-client-dialog.table {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px 20px;

      .ant-table-thead {
        th {
          border-top: none;
        }
      }

      tbody > tr:last-child > td {
        border-bottom: none;
      }
    }
    
  }
}

.ant-modal.roche-client-dialog.two-columns {
  .ant-modal-content {
    .ant-modal-body {
      padding: 33px 20px;
    }
  }
}

.ant-btn.light,
button.light:focus {
  background: @alpha-background-gray;
  border: none;
}

.ant-btn.light:hover {
  background: @alpha-background-gray-dark;
  color: #000000;
}
.ant-btn.light:disabled {
  opacity: 0.3;
  color: #000000;
}

.roche-table.ant-table-wrapper {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {    
    background: white;
    padding: 11px @alpha-card-padding;   
  }

  .ant-table-thead > tr > th {    
    border-bottom: 1px solid @alpha-border-color;
    border-top: none;
    color: @alpha-font-color-gray;
    font-size: 14px;
    &::before{
      display: none;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid @alpha-border-color;
    font-size: 14px;

    * {
      font-size: 14px;
    }

    .ant-tag.alpha-info-tag {
      font-size: 11px;
      vertical-align: middle;
    }

    .ant-select.priority-select {
      .ant-select-selector {
        padding-left: 0px;
      }
    }
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #F5F6F8;
    .closed-session-operation{
      visibility: visible;
    }
  }

  .ant-table-tbody > tr.closed > td {
    color: @alpha-font-color-gray;

    .priority-select, .ant-btn {
      color: @alpha-font-color-gray;
    }

    .ant-btn > span {
      text-decoration: line-through;
    }
  }

  .ant-table-tbody > tr.selected > td {
    background-color: @alpha-background-gray-dark;
  }

  .ant-table-tbody {
    color: @alpha-font-color-black;

    .ant-table-selection-column {
      overflow: hidden;
      text-overflow: clip;
    }
  }

  .ant-table-footer {
    background: transparent;
    text-align: center;
  }

  .table-footer-link {
    color: @primary-color;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
  }

  .table-footer-link:hover {
    color: @primary-color-hover;
  }

  .ant-pagination.ant-table-pagination {
    padding-right: 16px;
  }

  &.cursor-pointer tr {
    cursor: pointer;
  }

  .table-actions {
    display: flex;
    height: 16px;
    > div {
      margin-left: auto;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: unset;
    padding: 16px 10px 16px 0;
    background: #fff;
  }
  &.nested {
    .nested-row {
      background: #fff;
      .table-operation {
        button:not(.ant-switch) {
          visibility: hidden;
        }
        height: 22px;
      }
    }
    .nested-row:hover {
      .table-operation button {
        visibility: visible;
      }
    }
    tr.ant-table-expanded-row {
      > td > .ant-table-wrapper:only-child .ant-table {
        margin-left: 70px;
        margin-top: -11px;
        margin-bottom: -12px;
        tbody > tr:last-child > td {
          border-bottom: 1px solid @alpha-border-color;
        }
      }
      .ant-table-cell {
        background: #fff;
      }
    }
  }
}

.roche-table.ant-table-wrapper.no-border-top {
  .ant-table-thead > tr > th {
    border-top: none;
  }
}


.roche-table.ant-table-wrapper.fixed {
  table {
    table-layout: fixed !important;

    .ant-table-tbody > tr > td {
      overflow: hidden;
      text-overflow: ellipsis;
      
      .ant-btn, span {
        max-width: 100%;        
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
      }

      .ant-btn > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }
    }

    .ant-table-tbody > tr > td.ant-table-selection-column {
      overflow: hidden;
      text-overflow: clip;
    }
  }
}

.roche-table.ant-table-wrapper.sm-rows {
  .ant-table-tbody > tr > td {
    padding: 6px 16px;
  }
}

.roche-table.ant-table-wrapper.lg-title {
  .ant-table-thead > tr > th {
    padding: 6px 16px;
    white-space: nowrap;
    overflow: hidden;
    
    .ant-table-column-sorters {
      padding: 8px 0;
    }
  }
  .ant-table-tbody > tr > td {
    * {      
      font-size: 14px;
    }

    .ant-tag.alpha-info-tag {
      font-size: 11px;
    }

    .entry-title, .entry-title * {
      font-size: 14px;
    }
  }
}
.ant-layout-content.main-layout-content {
  background: @alpha-background-gray;
}

.roche-card {
  border-radius: 4px;
}

i.roche-icon {
  background-repeat: no-repeat;
  display: inline-block;
}

i.roche-icon{
  &.md {
    height: 20px;
    width: 20px;
  }
  &.sm {
    height: 15px;
    width: 15px;
  }
  &.middle {
    vertical-align: middle;
  }
}

.roche-search-input.ant-input-search {
  width: auto;
  transition: .3s;

  .ant-input-affix-wrapper {
    width: auto;

    .ant-input {
      width: 45px;
    }
    .ant-input-suffix {
      margin-left: 0;
    }


    .ant-input:focus {
      width: 250px;
    }
  }

  input.ant-input {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.roche-search-input.ghost {
  .ant-input-prefix {
    margin-right: -4px;
  }

  .ant-input-group .ant-input-affix-wrapper {
    border-radius: 4px;
  }

  .ant-input-affix-wrapper {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;

    input {
      background-color: transparent;
    }
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    background-color: white;
    border: 1px solid @alpha-border-color;
  }

  .ant-input-affix-wrapper:hover {
    background-color: white;
    border: 1px solid @primary-color-hover;
  }
}

.roche-search-input.no-search-btn {
  .ant-input-group {
    .ant-input-affix-wrapper {
      .ant-input {
        border-radius: 4px;
      }
    }
  }

  .ant-input-group-addon {
    display: none;
  }
}

.roche-client-dialog {
    &.large-input {
      input {
        height: 40px;
      }
      .ant-select-selector {
        height: 42px;
      }
      .ant-select-selection-item {
        line-height: 42px;
      }
    }
    .ant-modal-footer {
      button {
        font-size: 14px;
      }
    }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }
  &::after {
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    content: '*';
    display: inline-block;
    line-height: 1px;
    font-family: SimSun, sans-serif;
  }
}

input.ant-input::placeholder {
  color: #9BA0AA;
}

button.large {
  font-size: 14px;
}

.ant-card {
  .ant-card-head {
    border-bottom: unset;
  }
}

.ant-layout-header.app-page-header {
  .ant-menu.ant-menu-horizontal {
    .ant-menu-item.document-tab {
      i.doc {
        filter: brightness(0) invert(1);
      }
      .ant-row {
        line-height: 50px;
      }
    }

    .ant-menu-item.document-tab:after {
      display: none;
    }

    .ant-menu-item.document-tab {
      cursor: pointer;
      font-size: 15px;
      overflow: hidden;
      margin-left: -1px;
      margin-right: 0px;
      padding: 0;

      .tab-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 1px 18px 0 15px;
        white-space: nowrap;
        width: 250px;
        &.report {
          display: unset;
        }
        .roche-icon {
          vertical-align: middle;
          margin-right: 10px;
          &.folder{
            height: 16px;
            // padding-right: 25px;
          }
        }

        .title-text {
          color: #fff;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          width: 190px;
        }

        .title-container {
          width: 190px;
          display: inline-flex;
          max-width: calc(100% - 20px);
          flex-flow: column;
          transform: translateY(-4px);

          .main-title-text {
            color: #fff;
            height: 12px;
            line-height: 12px;
            margin-bottom: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .subtitle-text {
            color: @alpha-secondary-font-color;
            height: 10px;
            line-height: 10px;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .subtitle {
        color: #fff;
      }

      .btn-container {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin: 10px 0 10px auto;
        padding-right: 16px;
        vertical-align: top;

        .document-tab-btn {
          text-align: center;
          visibility: hidden;

          i {
            margin: 0;
          }
        }
      }

      &:hover{
        background-color: @primary-color-hover;
  
        .btn-container {
          .document-tab-btn {
            visibility: visible;
          }
        }
      }
      &.selected {
        background-color: @primary-color-hover;
      }
    }
  }
}

.document-tabs-container {
  color: white;
  height: 50px;

  .document-tabs-menu {
    cursor: pointer;
    padding: 0 16px;      
  }

  .document-tabs-menu:hover {
    background: @primary-color-hover;
  }
}

.table-actions-menu {
  .bordered {
    border-bottom: 1px solid #E4E5ED;
  }
  .bordered-top {
    border-top: 1px solid #E4E5ED;
  }
  .action-container {
    display: flex;
    align-items: center;
    padding: 6px;
    > div {
      margin-left: 10px;
    }
  }
  .ant-upload.ant-upload-select {
    width: 100%;
  }  
}

.page-header {
  .columns-btn {
    i {
      vertical-align: middle;
      height: 19px;
    }
    span {
      margin-left: 10px;
      color: #9BA0AA;
    }
    &:hover{
      i {
        filter: invert(16%) sepia(84%) saturate(1205%) hue-rotate(203deg) brightness(50%) contrast(86%);
      }
      span {
        color: #273C75
      }
    }
  }
  button {
    box-shadow: none;
  }
}

.ant-menu-submenu .ant-menu-item.document-tab {
  &:hover{
    background-color: #E4E5ED;
  }
  .btn-container {
    .document-tab-btn {
      display: table-caption;
    }
    i {
      filter: brightness(0%);
      margin-left: 10px;
    }
  }
}

.ant-menu-submenu-popup {
  .tab-title {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    i {
      filter: brightness(0%);
      margin-right: 10px;
    }
    .title-container {
      i{
        height: 22px;
      }
      .main-title-text {
        display: inline-block;
      }
      .subtitle-text {
        display: none;
      }
    }
  }
}

.app-version-label {
  border-top: 1px solid @alpha-secondary-border-color;
  color: @alpha-font-color-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  position: static;
  background-color: #F5F6F8;
  z-index: 100;
}

// Scroll styles
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #c2c2c2;
}

*{
  scrollbar-width: thin;
  scrollbar-color: #fff #fff;
}
*:hover{
  scrollbar-color:  #c2c2c2 #fff;
 }


.table-actions-menu {
  .action-container {
      .edit {
          filter: brightness(0%);
          height: 14px;
      }
      .view-in-doc-menu {
          filter: brightness(0%);
          height: 18px;
      }
      .download {
          height: 19px;
      }
      .comments {
          height: 17px;
      }
      .review {
        height: 10px;
        width: 22px;
        margin-right: -2px;
      }
  }
}

.ant-table-column-sorters {
  justify-content: unset;
  .ant-table-column-title {
    flex: unset;
  }
}

.project-list-item.ant-list-item {
  color: @alpha-font-color-black;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: left;
  padding-left: 15px;

  > div:first-child {
    flex: 0 0 25px;
  }

  .info-label {
    color: @alpha-font-color-gray;
    font-size: 11px;
    margin-right: 7px;
  }

  .additional-info {
    max-width: 600px;
  }
}

.project-tag {
  background:  @alpha-font-color-gray;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 3px;
  margin-right: 7px;
  padding: 2px 7px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.ant-list-split .project-list-item.ant-list-item  {
  border-bottom: unset;
}

.alpha-search-input .clear-search {
  background-size: contain;
}

.alpha-form.ant-form {
  label {
    color: @alpha-font-color-gray;
    font-size: 11px;
  }
}

div.logo-container {
  display: flex;
  height: 100%;
  align-items: center;
  i.alpha-icon.logo.lg{
    height: 26px;
    margin: 0 10px 0 0;
    width: 26px;
  }
}

.icon-uploader {
    > div.ant-upload {
      background: transparent;
    }
    img {
    max-height: 86px;
    max-width: 86px;
  }
}

.ant-message {
  z-index: 10000;
}
.confirm {
  .ant-modal-body {
    span {
      font-weight: bold;
    }
  }
}

.unread-comment-indicator {
  display: inline-block;
  width: 3px;
  height: 3px;
  background: red;
  vertical-align: middle;
  margin-left: 7px;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
}

.scroll-to-top {
  width: 26px;
  font-size: 20px;
  position: absolute;
  right: 12px;
  opacity: 0.3;
  cursor: pointer;
  z-index: 100;
}

.project-status-large {
  margin-left: 15px;
  color: #ffffff;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 11px;
  padding: 3px 7px 3px 7px;
}

.roche-logo {
  background-image: url('../../modules/common/resources/images/roche-logo-white.png');
  background-size: cover;
  border-radius: 4px;
  height: 32px;
  width: 48px;
  margin-left: 10px;
}

i.alpha-icon.logo {
  background-image: url('../../modules/common/resources/icons/alfa_fin.svg');
  border-radius: 4px;
  background-size: 26px 26px;
}

i.roche-icon.comment {
  background-image: url("../../modules/common/resources/icons/comment.svg");
}

i.roche-icon.layout {
  background-image: url("../../modules/common/resources/icons/layout.svg");
}

i.roche-icon.close-panel-icon {
  background-image: url("../../modules/common/resources/icons/close-panel.svg");
}

i.roche-icon.action-more-dots {
  background-image: url("../../modules/common/resources/icons/action-more-dots.svg");
}

i.roche-icon.action-more-dots-blue {
  background-image: url("../../modules/common/resources/icons/action-more-dots-blue.svg");
}

i.roche-icon.upload {
  background-image: url("../../modules/common/resources/icons/upload.svg");
}

i.roche-icon.plus {
  background-image: url("../../modules/common/resources/icons/plus.svg");
}

i.roche-icon.not-available-doc {
  background-image: url("../../modules/common/resources/icons/not-available-doc.svg");
}

i.roche-icon.doc {
  background-image: url("../../modules/common/resources/icons/doc.svg");
}

i.roche-icon.table-search {
  background-image: url("../../modules/common/resources/icons/table-search.svg");
}

i.roche-icon.columns {
  background-image: url("../../modules/common/resources/icons/columns.svg");
}

i.roche-icon.drag-handle {
  background-image: url("../../modules/common/resources/icons/drag-handle.svg");
}

i.roche-icon.archive {
  background-image: url("../../modules/common/resources/icons/archive.svg");
}

i.roche-icon.download {
  background-image: url("../../modules/common/resources/icons/download.svg");
}

i.roche-icon.remove {
  background-image: url("../../modules/common/resources/icons/remove.svg");
}

i.roche-icon.folder {
  background-image: url("../../modules/common/resources/icons/folder.svg");
}

i.roche-icon.edit {
  background-image: url("../../modules/common/resources/icons/edit.svg");
}

i.roche-icon.close-tab {
  background-image: url("../../modules/common/resources/icons/close-tab.svg");
}

i.roche-icon.view-in-doc {
  background-image: url("../../modules/common/resources/icons/view-in-doc.svg");
}

i.roche-icon.view-in-doc-blue {
  background-image: url("../../modules/common/resources/icons/view-in-doc-blue.svg");
}

i.roche-icon.comments {
  background-image: url("../../modules/common/resources/icons/comments.svg");
}

i.roche-icon.view-in-doc-menu {
  background-image: url("../../modules/common/resources/icons/view-in-doc-menu.svg");
}

i.roche-icon.cell-warning {
  background-image: url("../../modules/common/resources/icons/cell-warning.svg");
}

i.roche-icon.circled-check {
  background-image: url("../../modules/common/resources/icons/circled-check.svg");
}

i.roche-icon.circled-check {
  background-image: url("../../modules/common/resources/icons/circled-check.svg");
}

i.roche-icon.close-modal-cross {
  cursor: pointer;
  vertical-align: middle;
  height: 13px;
  background-image: url("../../modules/common/resources/icons/close-modal-cross.svg");
}

i.roche-icon.dash {
  background-image: url("../../modules/common/resources/icons/dash.svg");
}
i.roche-icon.raw-doc {
  background-image: url("../../modules/common/resources/icons/raw-doc.svg");
}

i.roche-icon.checked-doc {
  background-image: url("../../modules/common/resources/icons/checked-doc.svg");
}

i.roche-icon.review {
  background-image: url("../../modules/common/resources/icons/review.svg");
}

i.roche-icon.doc-small {
  background-image: url("../../modules/common/resources/icons/doc-small.svg");
}

i.roche-icon.bell {
  background-image: url("../../modules/common/resources/icons/bell.svg");
}

i.roche-icon.doc-add {
  background-image: url("../../modules/common/resources/icons/doc-add.svg");
}

i.roche-icon.arrow-up {
  background-image: url("../../modules/common/resources/icons/arrow-up.svg");
}

i.roche-icon.arrow-down {
  background-image: url("../../modules/common/resources/icons/arrow-down.svg");
}

i.roche-icon.collapse-arrow {
  background-image: url("../../modules/common/resources/icons/collapse-arrow.svg");
}

i.roche-icon.circle-cross {
  background-image: url("../../modules/common/resources/icons/circle-cross.svg");
}

i.roche-icon.doc-pdf {
  background-image: url('../../modules/common/resources/icons/doc-pdf.svg');
}

i.roche-icon.warning-triangle {
  background-image: url('../../modules/common/resources/icons/warning-triangle.svg');
}

i.roche-icon.importing-loading {
  background-image: url('../../modules/common/resources/icons/importing-loading.svg');
}

i.roche-icon.restore {
  background-image: url('../../modules/common/resources/icons/restore.svg');
}

i.roche-icon.not-read-comment {
  background-image: url("../../modules/common/resources/icons/not-read-comment.svg");
}

i.roche-icon.doc-small-completed {
  background-image: url("../../modules/common/resources/icons/doc-small-completed.svg");
}

i.roche-icon.circled-check-checked {
  background-image: url("../../modules/common/resources/icons/circled-check-checked.svg");
}

i.roche-icon.export {
  background-image: url('../../modules/common/resources/icons/export.svg');
}

i.roche-icon.gear {
  background-image: url('../../modules/common/resources/icons/gear.svg');
}

.no-access-to-portal {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

.ant-image-preview-img {
  max-width: 80% !important;
  max-height: 80% !important;
}

.ant-image-preview-operations {
  top: 50px !important;
}