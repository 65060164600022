@import './variables.less';

.roche-table.ant-table-wrapper.audit-table {
    .audit-comment {
        p {
            margin-bottom: 0;
        }
    }
    .edited-container {
        display: flex;
        align-items: center;
        color: #de790d;
        span {
            margin-left: 10px;
        }
    }
    .ant-table-thead > tr > th {
        padding: 11px 15px;
    }
}

.audit-comments-dialog {
    p {
        margin-bottom: 0;
    }
    .comment {
        margin-bottom: 20px;
    }
}

.audit-topics-table {
    td {
        vertical-align: top;
    }
   .result-container {
       max-width: 300px;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
        &.expanded {
           white-space: pre-wrap;
           text-overflow: unset;
           overflow: auto;
        }
   }
}
