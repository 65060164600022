.value-editor-container {
    .react-quill {
        height: 350px;

        .ql-container {
            border: 1px solid #E4E5ED;
            border-radius: 4px;

            .blot-formatter__toolbar-button {
                display: none !important;
            }

            .blot-formatter__resize-handle {
                display: none !important;
            }

            .blot-formatter__overlay {
                border: 1px solid #9BA0AA !important;
            }
        }
    }
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;