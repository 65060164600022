@import './variables.less';

.result-column-label {
  margin-bottom: 11px;
  font-size: 11px;
  color: @alpha-secondary-font-color;
  position: absolute;
  top: -40px;
}

.results-sticky-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  z-index: 1;
  position: sticky;
  top: -20px;
  
  .results-scrollable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    flex: 1;
    overflow-x: auto;
    transform: rotateX(180deg);
  
    .result-label-container {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 300px;
      max-width: 300px;
      height: 100%;
      transform: rotateX(180deg);
      align-items: center;
    
      .result-label {
        font-size: 11px;
        color: @alpha-secondary-font-color;
      }
    }

    .result-label-container:not(:first-child) {
      padding-left: 2.5px !important;
    }
  
    .result-label-container:not(:last-child) {
      padding-right: 2.5px !important;
    }
  }
}

.doc-matrix-table {
    display: flex;
    flex-direction: row;

    .topic-column {
      padding-right: 24px;
      flex-shrink: 0;
      padding-top: 40px;
      z-index: 2;
    }
    
    .result-column {
      flex: 1;
      overflow-x: auto;
    }

    .section-row {
      height: 60px;
      margin-bottom: 5px;

      .ant-col:not(:first-child) {
        padding-left: 2.5px !important;
      }
    
      .ant-col:not(:last-child) {
        padding-right: 2.5px !important;
      }
    }

    .fixed-size-section-column {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 300px;
      max-width: 300px;
    }
    
    .label {
        margin-bottom: auto;
        margin-top: auto;
        font-size: 12px;
        color: @alpha-secondary-font-color;
    }

    .topic-section-container {
      margin-bottom: 20px;
      .section {
        font-size: 12px;
        font-weight: bold;
        height: 20px;
        position: relative;
      }

      .section.has-menu {
        height: 12px;
        transform: translateY(-16px);
        z-index: 10000;
        
        .section-title-content {
          height: 12px;
        }
      }

      .section,
      .label {
        .action-button {
          visibility: hidden;
        }

        &:hover {
          .action-button {
            visibility: visible;
          }
        }
      }

      i.roche-icon.action-more-dots {
        height: 8px;
        width: 16px;
      }

      .section {
        .section-title-content {
          align-items: center;
          display: flex;
          height: 20px;
  
          .section-label {
            flex: 1 1;
          }
        }
      }

      .label {
        width: 100%;

        .content-flex {
          align-items: center;
          display: flex;

          .label-text {
            flex: 1 1;
          }

          .label-button {
            align-self: flex-end;
          }
        }
      }
    }

    .result-section-container {
      margin-bottom: 40px;
    }

    .matrix-cell {
        border: 1px solid #F5F6F8;
        height: 100%;
        border-radius: 4px;
        &.to-be-added:not(.unavailable) {
            align-items: center;
            justify-content: center;
            display: flex;
            > div:not(.comments-count) {
              display: none;
              width: 80px;
              i {
                vertical-align: middle;
                cursor: pointer;
                &.not-available-doc {
                  margin-left: auto;
                }
              }
            }
            &:hover {
              background: @alpha-secondary-background-color;
              > div:not(.comments-count):not(.upload-container) {
                display: flex;
              }
            }
        }
        &.unavailable {
            background: @alpha-background-gray;
            color: @alpha-font-color-gray;
            display: flex;
            flex-direction: column;
            .actions {
                align-self: flex-end;
            }
            > div:not(.comments-count) {
                display: none;
                &.not-available {
                  display: inline;
                  margin: auto;
                }
            }
            &:hover {
                div.not-available {
                  margin-top: -4px;
                }
                .actions {
                    display: inline;
                }
            }
            .not-available {
                align-self: center;
            }
        }
        &.processed {
            padding: 10px;
            font-size: 11px;
          }
          &.in-review {
            background-color: rgba(250, 140, 22, 0.2);
            .doc-name {
              color: #FA8C16;            
            }
          }
  
          &.UploadedWithoutAnalysis,
          &.Completed {
            background-color: rgba(82, 196, 26, 0.1);
            .doc-column, .status {
              color: #52C41A;            
            }
          }

          &.Broken {
            background-color: rgba(245, 34, 45, 0.2);
            .doc-column, .status {
              color: @alpha-font-color-red;
            }
          }

          &.Processing {
            i {
              background-size: contain;
            }
          }

        .status {
            display: none;
        }

        .upload-container {
          display: none;
        }

        .version {
            display: none;
        }
        .actions {
            display: none;
            margin-left: auto;
        }
        .doc-column {
            max-width: 90%;

            .doc-name-wrapper {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              position: absolute;
              width: 100%;
              height: 100%;
            }
        }
        &:hover {
            .version, .actions, .status {
                display: inline;
            }
        }

        .comments-count {
          position: absolute;
          right: 10px;
          bottom: 8px;
          height: 13px;
          width: 16px;
          cursor: pointer;
          color: @alpha-font-color-gray;
          > div{
              position: absolute;
              top: -1px;
              width: 100%;
              font-size: 10px;
              text-align: center;
              padding-top: 1px;
          }
          &.red {
            background: red;
            color: #fff;
          }
      }
    }
}

.ant-modal.alpha-new-contract {
    .selected-items-count {
        color: @alpha-font-color-gray;
        font-size: 12px;
        margin-right: 12px;
    }
  
    .ant-modal-body {
        padding: 0;
        input {
                text-overflow: ellipsis;
                border-right: none;
        }
        input:focus {
            box-shadow: none;
            border-color: #d9d9d9;
        }
        .ant-input-group:hover {
            .ant-input-group-addon {
                border: 1px solid #405482;
                border-left: none;
                }
            input {
                border-color: #405482;
            }
        }
        .ant-input-group-addon {
            background-color: #ffffff;
        }
    }
  
    .ant-modal-header {
        padding: 16px 15px;
        height: 50px;
    }

    .ant-modal-footer {
        height: 70px;
        button {
            height: 40px;
            border: none;
        }
    }
  
    .modal-header-container {
        user-select: none;
        margin: 20px 0 15px;
        padding: 0 15px;
        .analysis-sort {
            width: 40px;
            display: inline-block;
            text-align: left;
            margin: 0;
            cursor: pointer;
        }
    }

}
  
.ant-modal.alpha-package-progress-modal {
    color: @alpha-font-color-black;
  
    .ant-modal-body {
        padding: 70px 100px;
        text-align: center;
    }
    .action-title {
        font-size: 17px;
        font-weight: 700;
        margin: 24px 0 15px;
    }
  
    .action-description {
        font-size: 15px;
        margin-bottom: 25px;
    }
  
    .doc-preview-large {
        border: 1px solid @alpha-border-color;
        height: 200px;
        line-height: 200px;
        margin: 0 auto;
        overflow: hidden;
        width: 138px;
  
        img {
            max-height: unset;
            width: 100%;
        }
    }
}
  
.document-preview-placeholder {
    line-height: 417px;
    text-align: center;
  
    .placeholder-icon-container {
      height: 40px;
    }
  
    .document-placeholder-icon {
      display: inline-block;
      background-image: url("../../modules/common/resources/icons/doc-placeholder.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 40px;
      height: 48px;
    }
  
    .document-placeholder-message {
      color: @alpha-font-color-gray;
      font-size: 13px;
      text-align: center;
    }
  }
  
.ant-col.alpha-document-preview {
    background: @alpha-background-gray;
    padding: 37px 60px 0;
    height: 589px;
    min-height: 566px;
  
    img {
      max-height: 100%;
      max-width: 100%;
    }
  
    button,
    .pdf-sidebar-Container {
      display: none;
    }
  
    .file-info {
      color: @alpha-font-color-gray;
      font-size: 13px;
      text-align: center;
      margin: 15px auto 36px;
      max-height: 60px;
      overflow: hidden;
      .second-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  
    .pdf-content {
      overflow: auto;
    }
    .analysis-preview-container {
      height: 459px;
      text-align: center;
      line-height: 459px;
      overflow: hidden;
  
      img {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
        max-height: unset;
        width: 100%;
      }
  
      .package-set-content {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: flex-start;
        line-height: 24px;
        overflow-y: auto;
        overflow-x: hidden;
  
        > div {
          height: 24px;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
    .analysis-preview-container.multiple {
      transform: rotate(2deg);
    }
  
    .analysis-preview-container.multiple::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 94%;
      left: 0;
      transform: rotate(-2deg);
      background-color: #ffff;
      top: 16px;
      z-index: -1;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    }
}
  
.create-contract-document-loading {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
  
.ant-spin-spinning.sessions-loading-spinner {
    display: flex;
    height: 60vh;
    align-items: center;
    justify-content: center;
}

.modal-controls-container {
    margin: 20px 0 0;
    padding: 0 15px;
  
    .ant-input-search {
        .ant-btn {
            background-color: white;
            border-left: 1px solid transparent;
            color: @alpha-font-color-gray;
            padding: 1px 15px;
            width: 48px;
        }
  
        .ant-btn:hover, .ant-btn:focus {
            border-left: 1px solid @primary-color-hover;
            color: @alpha-font-color-gray-dark;
        }
  
        input {
            border-right: 1px solid;
        }
  
        input:not(:hover):not(:focus) {
            border-right-color: transparent;
        }
    }

    [data-id="new-contract-search-input"], button{
        height: 42px;
      }
    margin-bottom: 10px;
}

.project-list-item.ant-list-item.selected {
    background-color: @alpha-background-gray;
}
  
.dragging .doc-matrix-table {
  .matrix-cell.to-be-added:not(.unavailable) {
    border: none;

    .controls-container {
      display: none;
    }

    .upload-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      &.dragging {
        background: @alpha-secondary-background-color;
      }

      > span {
        display: block;
        width: 100%;
        height: 100%
      }

      > span .ant-upload.ant-upload-drag {
        border-color: @alpha-font-color-gray-dark;
        background: transparent;
        width: 100%;
        height: 100%;
      }
    }
  }
}
  
 