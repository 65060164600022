@import './variables.less';

.roche-client-dialog.comments {
    .comments-topic {
        font-size: 12px;
        color: @alpha-secondary-font-color;
        text-align: center;
        span {
            font-weight: bold;
        }
    }
    .empty-comments-title {
        font-size: 12px;
        color: @alpha-secondary-font-color;
        text-align: center;
    }

    .comment-container {
        display: flex;
        .author {
            font-size: 12px;
            span {
                color: @alpha-secondary-font-color;
            }
        }
        .date {
            font-size: 12px;
            color: @alpha-secondary-font-color;
            margin-bottom: 10px;
        }
    }

    .ant-modal-footer {
        border-top: none;
    }

    .unread-message-container {
        position: relative;
        .message {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 148px;
            bottom: -10px;
            color: @alpha-font-color-red;
            background: #fff;
            text-align: center;
        }
    }

}

.comment-in-modal.comments .comment-input-container {
    position: relative;
  
    .description-button-container {
      position: absolute;
      top: 0;
      right: -7px;
    }
    .comment-input-wrapper {
      background-color: white;
      border: 1px solid @alpha-border-color;
      position: relative;
  
      .alpha-quill.tb-bottom {
        .ql-container {
          border: none;
        }
  
        .ql-toolbar {
          border: none;
          bottom: 8px;
          display: none;
          position: absolute;
  
          button:not(.ql-active):not(:hover) .ql-stroke {
            stroke: @alpha-font-color-gray;
          }
  
          button:not(.ql-active):not(:hover) .ql-fill {
            fill: @alpha-font-color-gray;
          }
        }
      }
  
      .alpha-quill.tb-bottom.has-text {
        .ql-toolbar {
          display: block;
        }
      }
  
      .comment-input {
        border: none;
        border-radius: 0;
        box-shadow: none;
        height: 54px;
        resize: none;
  
        & + .comment-controls {
          display: none;
        }
      }
  
      .comment-input:focus,
      .comment-input.has-text {
        height: 64px;
        min-height: 32px;
  
        & + .comment-controls {
          display: block;
        }
      }
  
      .comment-controls {
        padding: 10px 13px;
        text-align: right;
      }
    }
  
}
.comment-form-item {
    .ant-form-item {
        margin-bottom: 0;
    }
    .quill.alpha-quill {
        position: relative;

        .ql-container.ql-snow {
            border-top: 1px solid @alpha-secondary-border-color;
            border: 1px solid @alpha-secondary-border-color;
            border-radius: 4px;
            height: 96px;
            padding-bottom: 30px;
            &.ql-disabled {
                background: #f5f5f5;
                color: rgba(0, 0, 0, 0.25);
            }
        }

        .ql-toolbar {
            bottom: 0;
            border: none;
            position: absolute;
            width: 100%;
            z-index: 100;

            .ql-fill {
                fill: @alpha-secondary-font-color;
            }

            .ql-stroke {
                stroke: @alpha-secondary-font-color;
            }
        }
    }
}


