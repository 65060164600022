@import './variables.less';

.projects-columns-menu {
    width: 253px;
    .draggable-item {
      display: inline-block;
      span {
        vertical-align: middle; 
        display: inline-block;
        width: 145px;
      }
      span.anticon {
          margin-right: 12px;
          width: 12px;
      }
    }  
    .droptarget.highlighted > div {
      border-top: 1px dashed rgb(116, 172, 224);
      border-bottom: 1px dashed rgb(116, 172, 224);
    
    }
}

.project-dashboard-wrapper {
  button.ant-btn {
    height: 40px;
  }
  .header {
    display: flex;
    align-items: center;

    .side-collapser {
      cursor: pointer;
      margin-right: 15px;;

      .roche-icon.close-panel-icon {
        background-size: contain;
        width: 24px;
        height: 24px;
      }
    }

    .right-container {
      margin-left: auto;
      color: @alpha-font-color-gray;
      i {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .project-title {
      font-size: 34px;
      font-weight: 500;
      line-height: 40px;
    }
  }
  .content {
    margin-top: 30px;
    display: flex;
    .right-cotainer {
      margin-left: 30px;
      transition: .3s;
      width: 100%;
      .method-sheet-report {
        .label {
          font-size: 11px;
          color: @alpha-secondary-font-color;
        }
      }
      .specifiaction-container {
        margin-top: 30px;
        margin-bottom: 30px;
        .specification-table {
          .status {
            color: #ffffff;
            border-radius: 10px;
            font-size: 11px;
            padding: 3px 7px 3px 7px;
            white-space: nowrap;
            &.completed {
              background: @alpha-color-success-light-green;
            }
            &.notUploaded {
              background: @alpha-font-color-gray;
            }
            &.broken {
              background: #F5222D;;
            }
            &.importing {
              background: @conf-level-average-color;
            }
            &.processing {
              background: #FA8C16;
            }
            &.without-analysis {
              background: #5867df;
            }
          }
          td, td > .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          td .name {
            max-width: 400px;
          }
          .action-more-dots {
            vertical-align: middle; 
            height: 4px;
            float: right;
            cursor: pointer;
          }
        }
      }
      .verification-plan-container {
        .ant-tabs {
          overflow: unset !important;
        }
        .ant-tabs-tab {
          color: @alpha-secondary-font-color;
        }
        .statuses {
          .label {
            font-size: 11px;
            white-space: nowrap;
            color: @alpha-secondary-font-color;
          }
          .value {
            font-size: 22px;
          }
        }
      }
    }
    .open-sessions-card {
      width: 250px;
      background: #ffffff;

      .view-all {
        margin-top: 20px;
        color: #273C75;
        cursor: pointer;
      }

      .ant-card-header-title {
        padding: 20px 0 25px;
      }

      .ant-card-body {
        padding-top: 0px;
      }

      .session-info:not(:last-child) {
        margin-bottom: 14px;
      }
      .label {
        font-size: 11px;
        color: @alpha-secondary-font-color;
      }
    }
    .activities-container {
      margin-top: 30px;
      width: 250px;
      .ant-card-body {
        max-height: 880px;
        overflow: auto;
        overflow-x: hidden;
      }
      .activity {
        display: flex;
        font-size: 12px;
        .activity-text {
          line-height: 14px;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          .light-grey {
            color: @alpha-secondary-font-color;
          }
          .comment {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      .activity-date {
        margin-top: 10px;
        margin-left: 32px;
        font-size: 12px;
        color: @alpha-secondary-font-color;
      }
      .view-all {
        margin-top: 20px;
        color: #273C75;
        cursor: pointer;
      }
    }

    .other-reports-container {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .left-container {
      transition: .3s transform;

      .activities-container, .open-sessions-card {
        transition: .15s;
      }
    }
  }

  .content.collapsed {
    .left-container {
      overflow: hidden;
      transition: .3s transform;
      transform: translateX(-350px);

      .activities-container, .open-sessions-card {
        transition: .3s;
        width: 0;
      }
    }

    .right-cotainer {
      transition: .3s;
      margin-left: 0;
    }
  }

  .project-dashboard-info-field {
    display: flex;

    .info-field-label {
      color: @alpha-font-color-gray;
      margin-right: 12px;
      flex: 0 0 100px;
    }

    // .info-field-value {

    // }
  }

  .ant-collapse.project-dashboard-collapse {
    .ant-collapse-header {
      font-size: 20px;

      .anticon.ant-collapse-arrow {
        vertical-align: middle;
      }
    }

    .ant-collapse-content {
      padding-left: 24px;
    }
  }
  .scroll-to-top {
    bottom: 50%;
  }
}

.k-spreadsheet-cell.k-state-disabled {
  opacity: 1;
}

.react-pdf__Page {
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
}

.project-status {
  color: #ffffff;
  height: 19px;
  padding: 3px 7px;
  border-radius: 10px;
  white-space: nowrap;
}

.roche-table.projects-table {
  th {
    .ant-table-column-title {
      white-space: break-spaces;
    }
  }
  th.ant-table-cell:last-child {
    font-size: 0;
  }
}

.action-container {
  > div {
    &.remove {
      color: @alpha-font-color-red;
    }
  }
  .upload {
    filter: brightness(0) saturate(100%)
  }
}

.ant-upload-disabled {
  .action-container {
    div {
      color: @alpha-font-color-gray;
    }
  }
}