.comment-button-container {
    margin-top: 12px;
    display: flex;
    justify-content: end;
    gap: 12px;
}

.comments-container {
    .ant-comment-actions {
        height: 24px;
        margin-left: -8px;
        margin-top: 0;
    }

    .ant-comment {
        .ant-comment-actions {
            visibility: hidden;
        }
    }
    
    .ant-comment:hover {
        .ant-comment-actions {
            visibility: visible;
        }
    }
}

.specifiaction-container.project-comments-container {
    .ql-container {
        min-height: 100px;
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;