@import './variables.less';

.summary-report {
    .ant-tabs-nav-wrap {
        position: sticky !important;
        left: 0;
        overflow: unset !important;
        flex: none !important;
    }

    .ant-collapse-header {
        padding-left: 0 !important;

        .panel-header {
            padding-left: 16px !important;
            position: sticky !important;
            left: 0;

            .anticon {
                display: inline-block;
                font-size: 12px;
                margin-right: 12px;
                vertical-align: -1px;
            }
        }
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }
    .roche-table {
        &.extended-results {
            table {
                thead th:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 2;
                }
            
                tbody td:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }
        
                thead th:nth-child(2) {
                    position: sticky;
                    left: 300px;
                    z-index: 2;
                }
            
                tbody td:nth-child(2) {
                    position: sticky;
                    left: 300px;
                    z-index: 1;
                }
            }   
        }
        table {
            table-layout: fixed !important;
            border-top: 0 !important;
        }
        margin-bottom: 10px;
        &.concise {
            td {
                max-width: 310px;
            }
            .table-cell {
                .result-value-container {
                    white-space: nowrap;
                    overflow: hidden;
                    .result-value {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &.without-cell-scrollbar {
            .ant-table-cell-scrollbar {
                display: none;
            }
        }
    }
    .header-details {
        margin-left: 20px;
        display: flex;
        color: white;
        font-size: 11px;
        >div {
            padding: 3px 7px 3px 7px;
            border-radius: 10px;
            &.version {
                margin-right: 15px;
                background-color: @alpha-background-grey-color;
            }
        }
      
    }
    .ant-tabs-tab:not(.ant-tabs-tab-active) .spec-name {
        color: @alpha-font-color-gray;
    }


    .roche-table.ant-table-wrapper .ant-table-tbody > tr {
        td, td .table-cell .result-value-container * {
            font-size: 12px;
        }

        td {
            vertical-align: top;
        }
        .table-cell {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            .enter-data-label {
                color: #9BA0AA;
                visibility: hidden;
                cursor: pointer;
            }
            i.cell-warning {
                vertical-align: middle;
                margin-right: 10px;
            }
            i.view-in-doc {
                height: 13px;
                margin-left: 15px;
                margin-right: 4px;
            }
            i.action-more-dots {
                height: 9px;
                margin-left: 5px;
                margin-right: 4px;
            }
            i.circled-check, i.circled-check-checked {
                vertical-align: middle;
                margin-right: 15px;
                cursor: pointer;

            }
            i:not(.circled-check-checked,.cell-warning,.comment,.not-read-comment) {
                cursor: pointer;
                visibility: hidden;
            }
            .ant-col {
                align-self: flex-start;
            }
            .hidden-items {
                display: none;
                position: absolute;
                top: 13px;
                height: 17px;
                right: 42px;
                background: @alpha-background-gray;
                border-radius: 3px;
            }
        }
        td.ant-table-cell:hover {
            .table-cell {
                i, .enter-data-label {
                    visibility: visible;
                }
                .hidden-items {
                    display: inline-block;
                }
            }
        }

        td.ant-table-cell.highlighted-block-cell {
            background-color: rgb(144 238 144 / 30%);
            i {
                visibility: visible;
            }

            .view-in-doc {
                background-image: url("../../modules/common/resources/icons/view-in-doc-blue.svg");
            }

            .action-more-dots {
                background-image: url("../../modules/common/resources/icons/action-more-dots-blue.svg");
            }
            .hidden-items {
                background-color:  #DDFADD;;
            }
        }

        td:nth-child(2),
        td:last-child {
            background: @alpha-background-gray;
        }

        .table-cell{
            .comments-count {
                display: inline-block;
                height: 13px;
                width: 16px;
                color: @alpha-font-color-gray;
                margin-left: auto;
                margin-top: 3px;
                cursor: pointer;
                > div{
                    position: absolute;
                    top: -1px;
                    width: 100%;
                    font-size: 10px;
                    text-align: center;
                    padding-top: 1px;
                }
                &.red {
                    color: #fff;
                }
            }
            .not-available {
                color: @alpha-secondary-font-color;
            }
        }
    }

}

.page-header {
    i.download {
        filter: brightness(0) saturate(100%) invert(74%) sepia(18%) saturate(154%) hue-rotate(182deg) brightness(84%) contrast(85%);
        background-size: cover;
        height: 24px;
        width: 28px;
        margin-right: 10px;
    }
}

.preview-doc-wrapper {
    .highlight-block {
        background-color: lightgreen;
        opacity: 0.3;
        position: absolute;
        z-index: 500;
    }

    .react-pdf__Page {
        margin-bottom: 24px;
    }
}

.summary-report {
    .summary-report-content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: calc(100vh - 220px);

        .highlight-block {
            background-color: lightgreen;
            opacity: 0.3;
            position: absolute;
            z-index: 500;
        }

        .react-pdf__Page {
            margin-bottom: 24px;
        }

        .ant-tabs {
            flex-shrink: 1;
            overflow: auto;
        }

        .document-preview-placeholder {
            flex-grow: 1;
            flex-basis: calc(50vh - 128px);
            min-height: calc(50vh - 128px);
        }

        .document-preview-placeholder.collapsed {
            flex-basis: 23px;
            min-height: 23px;
        }

        .document-preview-wrapper {
            background-color: @alpha-background-grey-color;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);
            border-radius: 4px 4px 0px 0px;
            overflow: auto;
            position: absolute;
            bottom: 42px;
            height: calc(50vh - 100px);
            width: 100%;
            left: 0;
        }

        .document-preview-wrapper.collapsed {
            height: 48px;
            overflow: hidden;
        }

        .document-preview-wrapper.detached, .document-preview-placeholder.detached {
            visibility: hidden;
        }

        .ant-tabs .ant-tabs-nav {
            background-color: @alpha-secondary-background-color;
            position: sticky;
            top: 0;
            z-index: 1000;
            margin: 0;
            padding-bottom: 16px;
        }

        .ant-tabs .ant-collapse-header {
            background: #fafafa;
            position: sticky;
            top: 62px;
            z-index: 900;
            border-bottom: 1px solid #f0f0f0;
        }

        .ant-tabs .ant-table-thead {
            background: white;
            position: sticky;
            top: 109px;
            z-index: 900;
        }

        .document-preview-header {
            background-color: @alpha-background-gray;
            cursor: pointer;
            font-size: 14px;
            padding: 12px 30px;
            position: sticky;
            top: 0;
            z-index: 1000;

            i.sm {
                background-position: center;
                display: inline-block;
                height: 24px;
                margin-right: 10px;
                vertical-align: middle;
                width: 24px;
            }

            .panel-header-title {
                color: black;
                display: inline-block;
                font-weight: 500;
                margin-right: 8px;
                vertical-align: middle;
            }
        }

        .document-preview-header:hover {
            background-color: #e7e7e7;
        }

        .ant-collapse-header{
            font-weight: 500;
        }

        .scroll-to-top {
            bottom: 48%;
        }
    }
}