.html-renderer {
    p {
        margin-bottom: 0px;

        img {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}
@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;